export default {
  topBox: {
    height: "30vw", 
    width: "100%", 
    overflow: "hidden"
  },
  topBoxImgContTop: { 
    background: "linear-gradient(0deg,#000000bd,#00000014)", 
    width: "100%", 
    height: "30vw", 
    position: 'absolute' 
  },
  topBoxImgCont: { 
    width: "100%", 
    height: "30vw", 
    position: "fixed", 
    zIndex: -1,
    textAlign: "center",
    backgroundColor: "#000"
  },
  logo: { 
    width: "25vw" 
  },
  topBoxTextCont: { 
    position: "fixed", 
    width: "100%"
  },
  topBoxText: { 
    color: "#FFF", 
    letterSpacing: 1.2, 
    padding: "10px 30px", 
    margin: "auto" 
  },
  root: {
    paddingTop: 20,
    minHeight: 600,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0
  },
  topCont: {
    boxShadow: "0px 0px 80px -15px rgba(0,0,0,1)",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  boxLetterCont: {
    padding: "40px 10px 140px",
    justifyContent: "center"
  },
  boxLetter: {
    flex: 1,
    maxWidth: 120,
    maxHeight: 120
  },
  paper: ({theme}) => {
    return {
      // padding: theme.spacing(2),
      textAlign: 'center',
      // color: theme.palette.common.white,
      // backgroundColor: theme.palette.warning.light,
      fontSize: "3rem",
      // fontFamily: ['Space Mono', 'Consolas', 'Arial'].join(",")
    }
  },
  btnMulai: {
    marginBottom: 50,
    width: "100%",
    fontSize: 30,
    "&.Mui-disabled": {
      backgroundColor: "#aaa",
      color: "#FFF"
    }
  },
  mainVideo: {
    width: "100%", 
    height: "515px"
  },
  '@media (max-width: 1024px)': {
    topBox: {
      height: "40vw"
    },
    topBoxImgContTop: {
      height: "40vw"
    },
    topBoxImgCont: {
      height: "40vw"
    }
  },
  '@media (max-width: 768px)': {
    topBox: {
      height: "50vw"
    },
    topBoxImgContTop: {
      height: "50vw"
    },
    topBoxImgCont: {
      height: "50vw"
    },
    logo: { 
      width: "35vw" 
    },
  },
  '@media (max-width: 425px)': {
    topBox: {
      height: "60vw"
    },
    topBoxImgContTop: {
      height: "60vw"
    },
    topBoxImgCont: {
      height: "60vw"
    },
    logo: { 
      width: "40vw" 
    },
  },
  '@media (max-width: 375px)': {
    topBox: {
      height: "65vw"
    },
    topBoxImgContTop: {
      height: "65vw"
    },
    topBoxImgCont: {
      height: "65vw"
    },
  },
  '@media (max-width: 320px)': {
    topBox: {
      height: "70vw"
    },
    topBoxImgContTop: {
      height: "70vw"
    },
  },
  '@media (max-width: 500px)': {
    mainVideo: {
      width: "100%", 
      height: "235px"
    }
  }
};