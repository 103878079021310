import React from 'react'
import { Box, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  fullScreenLoading: {
    position: "fixed",
    height: "100vh",
    width: "100vw",
    top: 0,
    left: 0,
    backgroundColor: "rgba(255,255,255,.5)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 1s",
    "& p": {
      fontSize: 20,
      textShadow: "0px 0px 10px rgba(0,0,0,0.59)"
    }
  }
});

export default props => {
  const classes = useStyles();
  let loadingStyle = props.loading ? { opacity: 1, zIndex: 10000 } : { opacity: 0, zIndex: -1 };

  return (
    <Box className={classes.fullScreenLoading} style={loadingStyle}>
      <CircularProgress />
      <p>Loading...</p>
    </Box>
  )
}