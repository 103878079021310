import React, { Component } from 'react';
import { Container, Grid, TextField, Button, RadioGroup, FormControlLabel, Radio, FormLabel, FormControl, Input } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Icon from '@mdi/react';
import { mdiContentSave } from '@mdi/js';
import '../../App.css';
import API from '../../API';
import Table from './Table';
import LoadingSpinner from '../LoadingSpinner';

const styles = {
  root: {
    paddingLeft: 60,
    paddingTop: 20,
    minHeight: 600,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center"
  },
  inputText: {
    backgroundColor: "#FFF"
  },
  inputRadio: {
    marginTop: 20,
    marginBottom: 40,
    display: "flex",
    flexDirection: "row",
    marginLeft: 10,
    "& legend": {
      textAlign: "left"
    },
    "& .MuiFormGroup-root": {
      flexDirection: "row"
    }
  }
};

class AdminHome extends Component {
  inputRefs = {
    title: null,
    deskripsi: null,
    media_type: null,
    files: null,
    file_logo: null,
  }

  state = {
    datasVideo: [],
    datasDeskripsi: [],
    loading: true,
    title: "",
    deskripsi: "",
    media_type: "image",
    media_url: "",
  };

  apiVideo = {
    get: {
      url: "content-video-admin",
      method: "GET"
    },
    put: {
      url: "content-video-admin/",
      method: "PUT"
    },
    post: {
      url: "content-video-admin",
      method: "POST"
    },
    delete: {
      url: "content-video-admin/",
      method: "DELETE"
    },
  }

  apiDeskripsi = {
    get: {
      url: "deskripsi-home-admin",
      method: "GET"
    },
    put: {
      url: "deskripsi-home-admin/",
      method: "PUT"
    },
    post: {
      url: "deskripsi-home-admin",
      method: "POST"
    },
    delete: {
      url: "deskripsi-home-admin/",
      method: "DELETE"
    },
  }

  async componentDidMount() {
    this.refreshDatas();
  }

  async refreshDatas() {
    let datasVideo = await this.getVideo();
    let datasDeskripsi = await this.getDeskripsi();
    // console.log("datasDeskripsi", datasDeskripsi);
    
    if (datasDeskripsi && datasDeskripsi.length > 0) {
      datasDeskripsi = {
        title: datasDeskripsi[0].title,
        deskripsi: datasDeskripsi[0].deskripsi,
        media_type: datasDeskripsi[0].media_type,
      }
    }
    else
      datasDeskripsi = {};
    
    // console.log("datasDeskripsi 2", datasDeskripsi);
    this.setState({ datasVideo, ...datasDeskripsi, loading: false });
  }

  async getVideo() {
    let data = null;
    try {
      data = await API({
        api: this.apiVideo.get.url,
        params: {
          method: this.apiVideo.get.method,
          headers: {
            'Content-Type': 'application/json',
            "Authorization": localStorage.getItem("token")
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }
    
    return data;
    // this.setState({ loading: false });
  }

  async putVideo(data) {
    this.setState({ loading: true });
    let { id } = data;
    delete data.id;
    console.log("data", data);
    try {
      await API({
        api: `${this.apiVideo.put.url}${id}`,
        params: {
          method: this.apiVideo.put.method,
          headers: {
            'Content-Type': 'application/json',
            "Authorization": localStorage.getItem("token")
          },
        },
        data
      });
    } catch (error) {
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }
    this.refreshDatas();
  }

  async deleteVideo(id) {
    this.setState({ loading: true });
    console.log("id", id);
    try {
      await API({
        api: `${this.apiVideo.delete.url}${id}`,
        params: {
          method: this.apiVideo.delete.method,
          headers: {
            'Content-Type': 'application/json',
            "Authorization": localStorage.getItem("token")
          },
        },
      });
    } catch (error) {
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }
    this.refreshDatas();
  }

  async postVideo(data) {
    this.setState({ loading: true });
    console.log("data", data);
    try {
      await API({
        api: this.apiVideo.post.url,
        params: {
          method: this.apiVideo.post.method,
          headers: {
            'Content-Type': 'application/json',
            "Authorization": localStorage.getItem("token")
          },
        },
        data
      });
    } catch (error) {
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }
    this.refreshDatas();
  }
  
  async getDeskripsi() {
    let data = null;
    try {
      data = await API({
        api: this.apiDeskripsi.get.url,
        params: {
          method: this.apiDeskripsi.get.method,
          headers: {
            'Content-Type': 'application/json',
            "Authorization": localStorage.getItem("token")
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }
    
    return data;
    // this.setState({ loading: false });
  }

  async postDeskripsi() {
    this.setState({ loading: true });
    let formData = this.generateFormDataDeskripsi();
    console.log("formData", formData);
    try {
      await API({
        api: this.apiDeskripsi.post.url,
        params: {
          method: this.apiDeskripsi.post.method,
          // mode: "cors",
          headers: {
            // 'Content-Type': 'multipart/form-data',
            "Authorization": localStorage.getItem("token")
          },
        },
        formData
      });
    } catch (error) {
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }
    this.refreshDatas();
  }

  generateFormDataDeskripsi() {
    let { title, deskripsi, files, file_logo } = this.inputRefs;
    let { media_type } = this.state;
    
    let formData = new FormData();
    formData.append("title", title.value);
    formData.append("deskripsi", deskripsi.value);
    formData.append("media_type", media_type);
    if (file_logo.files[0]) {
      formData.append("file_logo", file_logo.files[0]);
    }
    if (files.files[0]) {
      formData.append("media_url", files.files[0]);
    }

    return formData;
  }

  render() {
    let { datasVideo, loading, title, deskripsi, media_type } = this.state;
    let { classes } = this.props;
    console.log("title", title);
    
    return (
      <Container maxWidth="lg" className={classes.root}>
        
        <Grid container style={{ marginBottom: 50 }} spacing={8}>

          <h1 style={{ marginLeft: "auto", marginRight: "auto" }}>Pengaturan Homepage</h1>
          <Grid item xs={12}>
            <h2 style={{ borderBottom: "1px solid black", paddingBottom: 10 }}>Pengaturan Content Video</h2>
            <Table
              rows={datasVideo || []}
              saveRow={dt => this.putVideo(dt)}
              deleteRow={id => this.deleteVideo(id)}
              addRow={dt => this.postVideo(dt)}
            />
          </Grid>
          <Grid item xs={12}>
            <h2 style={{ borderBottom: "1px solid black", paddingBottom: 10 }}>Pengaturan Deskripsi</h2>
            <form noValidate autoComplete="off" style={{ textAlign: "center" }} onSubmit={() => console.log("form submit")}>
              
              <TextField
                id="page-title"
                label="Judul"
                rows="1"
                multiline
                style={{ margin: 8 }}
                placeholder="Placeholder"
                // helperText="Full width!"
                fullWidth
                margin="normal"
                defaultValue={title}
                className={classes.inputText}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                inputRef={ref => this.inputRefs.title = ref}
              />
              <TextField
                id="deskripsi"
                label="Deskripsi"
                rows="5"
                multiline
                style={{ margin: 8 }}
                placeholder="Placeholder"
                // helperText="Full width!"
                fullWidth
                margin="normal"
                defaultValue={deskripsi}
                className={classes.inputText}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                inputRef={ref => this.inputRefs.deskripsi = ref}
              />
              <FormControl component="fieldset" className={classes.inputRadio}>
                <FormLabel component="legend">Logo</FormLabel>
                <Input type="file" name="file_logo" inputRef={ref => this.inputRefs.file_logo = ref} />
              </FormControl>
              <FormControl component="fieldset" className={classes.inputRadio}>
                <FormLabel component="legend">Tipe Media</FormLabel>
                <RadioGroup
                  aria-label="tipe media"
                  name="tipe_media" 
                  id="tipe_media" 
                  onChange={v => this.setState({ media_type: v.nativeEvent.target.value })}
                  // inputRef={ref => this.inputRefs.media_type = ref}
                >
                  <FormControlLabel value="image" control={<Radio />} label="Image" checked={"image" === media_type} />
                  <FormControlLabel value="video" control={<Radio />} label="Video" checked={"video" === media_type} />
                </RadioGroup>
                <Input type="file" name="file" inputRef={ref => this.inputRefs.files = ref} />
              </FormControl>

              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ margin: "auto", paddingLeft: 70, paddingRight: 70 }}
                onClick={() => this.postDeskripsi()}
                startIcon={
                  <Icon path={mdiContentSave}
                    title="Save"
                    size={1}
                    color="white"
                  />
                }
              >
                Save
              </Button>

            </form>
          </Grid>

        </Grid>
        <LoadingSpinner loading={loading} />

      </Container>
    )
  }
}

export default withTheme(withStyles(styles)(AdminHome));