import React, { Component } from 'react'
import { Container, Grid, TextField, withTheme, withStyles } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';

const styles = {
  root: {
    paddingTop: 40,
    minHeight: 650,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    "& .MuiGrid-container": {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center"
    }
  },
  textField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      // backgroundColor: "#FFF",
      borderRadius: 15,
    },
    "& .MuiInputBase-input": {
      color: "#000",
      textAlign: "center",
      fontSize: "3rem",
      fontWeight: "bold",
      letterSpacing: 10,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 2,
      borderColor: "rgba(32,32,82,.5)",
      "& legend": {
        fontSize: 15
      }
    }
  },
};

class Terdaftar extends Component {
  render() {
    let { classes, match: { params: { kode } } } = this.props;
    
    return (
      <Container maxWidth="lg" className={classes.root}>

        <Grid container>
          <p style={{ fontSize: "2rem" }}>
            Selamat! Anda telah terdaftar untuk mengikuti undian.
          </p>
        </Grid>
        <Grid container style={{ flexDirection: "column" }}>
          <Grid item xs={10}>
            <p style={{ fontSize: "1.5rem" }}>
              Berikut adalah nomor undian Anda :
            </p>
          </Grid>
          <Grid item xs={8}>
            <TextField
              disabled
              className={classes.textField}
              id="kode"
              value={kode}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={10}>
            <p style={{ fontSize: "1.5rem" }}>
              Anda dapat melihat daftar pemenang dengan <Link to="/">Klik Disini</Link>
            </p>
          </Grid>
        </Grid>
        <Grid container style={{ alignItems: "center" }} spacing={3}>
          <Grid item>
            <p style={{ fontSize: "1.5rem" }}>
              Klik ikon dibawah untuk melihat Live Streaming.
            </p>
          </Grid>
          <Grid item>
            <a href="https://www.facebook.com/rudigamingpro/" target="_blank" rel="noopener noreferrer">
              <img src={require("../assets/facebook_logos_PNG19748.png")} style={{ margin: "0 20px", width: 50 }} alt="fb ico" />
            </a>
            <a href="https://www.youtube.com/channel/UCZ-AYS4wZb6fdHwKiniAMzQ" target="_blank" rel="noopener noreferrer">
              <img src={require("../assets/youtube-ico.png")} style={{ margin: "0 20px", width: 70 }} alt="yt ico" />
            </a>
          </Grid>
        </Grid>

      </Container>
    )
  }
}

const applyTheme = withTheme(withStyles(styles)(Terdaftar));
export default withRouter(applyTheme);