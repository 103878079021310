import React, { Component } from 'react';
import { Container, Grid } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import '../../App.css';
import API from '../../API';
import Table from './Table';
import LoadingSpinner from '../LoadingSpinner';

const styles = {
  root: {
    paddingLeft: 60,
    paddingTop: 20,
    minHeight: 600,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center"
  },
};

class Home extends Component {
  state = {
    datas: [],
    loading: true,
  };

  api = {
    get: {
      url: "peserta-admin",
      method: "GET"
    },
    put: {
      url: "peserta-admin/",
      method: "PUT"
    },
    post: {
      url: "peserta-admin",
      method: "POST"
    },
    delete: {
      url: "peserta-admin/",
      method: "DELETE"
    },
  }

  async componentDidMount() {
    let datas = await this.getDatas();
    this.setState({ datas, loading: false });
  }

  async refreshDatas() {
    let datas = await this.getDatas();
    this.setState({ datas, loading: false });
  }

  async getDatas() {
    let data = null;
    try {
      data = await API({
        api: this.api.get.url,
        params: {
          method: this.api.get.method,
          headers: {
            'Content-Type': 'application/json',
            "Authorization": localStorage.getItem("token")
          },
        },
      });
    } catch (error) {
      console.log("error", error, this.get.url);
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }
    
    return data;
    // this.setState({ loading: false });
  }

  async putDatas(data) {
    this.setState({ loading: true });
    let { id } = data;
    delete data.id;
    console.log("data", data);
    try {
      let res = await API({
        api: `${this.api.put.url}${id}`,
        params: {
          method: this.api.put.method,
          headers: {
            'Content-Type': 'application/json',
            "Authorization": localStorage.getItem("token")
          },
        },
        data
      });
      if (typeof res.code === "undefined" || res.code !== 201) {
        let error = [];
        for (const k in res) {
          if (res.hasOwnProperty(k)) {
            error = error.concat(res[k]);
          }
        }
        throw new Error(error.join(`,`));
      }
    } catch (error) {
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }
    this.refreshDatas();
  }

  async deleteDatas(id) {
    this.setState({ loading: true });
    console.log("id", id);
    try {
      await API({
        api: `${this.api.delete.url}${id}`,
        params: {
          method: this.api.delete.method,
          headers: {
            'Content-Type': 'application/json',
            "Authorization": localStorage.getItem("token")
          },
        },
      });
    } catch (error) {
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }
    this.refreshDatas();
  }

  async postDatas(data) {
    this.setState({ loading: true });
    console.log("data", data);
    try {
      await API({
        api: this.api.post.url,
        params: {
          method: this.api.post.method,
          headers: {
            'Content-Type': 'application/json',
            "Authorization": localStorage.getItem("token")
          },
        },
        data
      });
    } catch (error) {
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }
    this.refreshDatas();
  }

  render() {
    let { datas, loading } = this.state;
    let { classes } = this.props;
    // console.log("datas", datas);
    
    return (
      <Container maxWidth="lg" className={classes.root}>
        
        <Grid container style={{ marginBottom: 50 }}>
          <h1 style={{ marginLeft: "auto", marginRight: "auto" }}>Daftar Peserta</h1>
          <Table
            rows={datas}
            saveRow={dt => this.putDatas(dt)}
            deleteRow={id => this.deleteDatas(id)}
            addRow={dt => this.postDatas(dt)}
          />
        </Grid>
        <LoadingSpinner loading={loading} />
        
      </Container>
    )
  }
}

export default withTheme(withStyles(styles)(Home));