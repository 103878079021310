import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from 'react-router-dom';

// Admin Only
import Undian from './Pages/Undian';
import AdminPeserta from './Pages/AdminPeserta';
import AdminPemenang from './Pages/AdminPemenang';
import AdminUsers from './Pages/AdminUsers';
import Home from './Pages/Home';

import Registration from './Pages/Registration';
import Terdaftar from './Pages/Terdaftar';
import Login from './Pages/Login';
// import Pemenang from './Pages/Pemenang';
import { checkAuth } from "./Library";
import AdminMenu from './Pages/AdminMenu';
import AdminHome from './Pages/AdminHome';

class App extends React.Component {
  state = {
    isAuth: false,
    loading: true,
  }

  async componentDidMount() {
    let auth = await checkAuth();
    this.setState({ isAuth: auth, loading: false });
  }

  async checkToken() {
    this.setState({ loading: true });
    let auth = await checkAuth();
    this.setState({ isAuth: auth, loading: false });
  }
  
  render() {
    let { isAuth, loading } = this.state;
    if (loading) return null;

    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <PrivateRoute exact path="/admin-home" auth={isAuth}>
            <AdminMenu />
            <AdminHome />
          </PrivateRoute>
          <PrivateRoute exact path="/admin-undian" auth={isAuth}>
            <AdminMenu />
            <Undian />
          </PrivateRoute>
          <PrivateRoute exact path="/admin-peserta" auth={isAuth}>
            <AdminMenu />
            <AdminPeserta />
          </PrivateRoute>
          <PrivateRoute exact path="/admin-pemenang" auth={isAuth}>
            <AdminMenu />
            <AdminPemenang />
          </PrivateRoute>
          <PrivateRoute exact path="/admin-users" auth={isAuth}>
            <AdminMenu />
            <AdminUsers />
          </PrivateRoute>
          {/* <Route exact path="/pemenang">
            <Pemenang />
          </Route> */}
          <Route exact path="/login55">
            <Login onRefresh={async () => this.checkToken()} />
          </Route>
          <Route path="/register">
            <Registration />
          </Route>
          <Route path="/registration-success/:kode">
            <Terdaftar />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Router>
    );
  }
}
export default App;

function PrivateRoute({ children, auth, ...rest }) {
  // console.log("rest", rest);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <NoMatch />
        )
      }
    />
  );
}

function NoMatch() {
  let history = useHistory();
  setTimeout(() => history.push("/"), 5000);
  return (
    <div style={{ backgroundColor: "#F8F8F8", height: "100vh", width: "100vw", position: "fixed", display: "flex" }}>
      <img src={require("./assets/404.png")} style={{ margin: "auto" }} alt="404" />
    </div>
  );
}