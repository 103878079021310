import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { Grid } from '@material-ui/core';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  changePage: {
    cursor: "pointer",
    "& .MuiPaper-root": {
      backgroundColor: "#3f51b5",
      color: "#FFF",
      transition: ".5s all"
    },
    "&:hover .MuiPaper-root": {
      backgroundColor: "#FFF",
      color: "#3f51b5",
      transition: ".5s all"
    }
  },
  changePageActive: {
    cursor: "pointer",
    "& .MuiPaper-root": {
      backgroundColor: "#FFF",
      color: "#3f51b5",
      transition: ".5s all"
    }
  }
});

export default function CustomizedTables(props) {
  const classes = useStyles();
  const { datas, changePage, loading, tipe } = props;
  let rows = datas.data || [];
  let current_page = typeof datas.current_page === 'number' ? datas.current_page : 1;
  // console.log("table datas", datas);
  let paginationList = [];
  for (let i = 1; i <= datas.last_page; i++) {
    paginationList.push({
      text: i
    });
  }
  
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ maxWidth: 30 }}>No</StyledTableCell>
            <StyledTableCell>Nama</StyledTableCell>
            <StyledTableCell>{tipe === "pemenang" ? `Tanggal Undian` : `Tanggal Daftar`}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            loading &&
            <TableRow>
              <StyledTableCell
                colSpan={4}
                style={{ textAlign: "center" }}
              >
                <Grid container justify="center" spacing={3}>
                  <Grid item>
                    Loading...
                  </Grid>
                </Grid>
              </StyledTableCell>
            </TableRow>
          }
          {!loading && rows.map((row, i) => (
            <StyledTableRow key={`${row}+${i}`}>
              <StyledTableCell style={{ maxWidth: 30 }}>{(i+1)+((datas.current_page-1) * datas.per_page)}</StyledTableCell>
              <StyledTableCell>
                {row.nama}
              </StyledTableCell>
              <StyledTableCell>{moment(`${row.updated_at} +0000`).format("DD MMM YYYY - HH:mm:ss")}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <StyledTableCell
              colSpan={4}
              style={{ textAlign: "center" }}
            >
              <Grid container justify="center" spacing={1}>
                {
                  datas.current_page-1 > 0 &&
                  <Grid item onClick={() => changePage(datas.current_page-1)} className={classes.changePage}>
                    <Paper elevation={3} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 3 }}>{`<`}</Paper>
                  </Grid>
                }
                {
                  paginationList.map(v => (
                    <Grid item key={v.text} onClick={() => changePage(v.text)} className={current_page === v.text ? classes.changePageActive : classes.changePage}>
                      <Paper elevation={3} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 3 }}>{v.text}</Paper>
                    </Grid>
                  ))
                }
                {
                  (datas.current_page * datas.per_page) <= datas.total &&
                  <Grid item onClick={() => changePage(datas.current_page+1)} className={classes.changePage}>
                    <Paper elevation={3} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 3 }}>{`>`}</Paper>
                  </Grid>
                }
              </Grid>
            </StyledTableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
