import React, { Component } from 'react';
import { Container, Grid, Paper, Button, Box } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import '../App.css';
import API, { api_list, base_url } from '../API';
import Table from './Table';
import TablePeserta from './TablePeserta';
import LoadingSpinner from './LoadingSpinner';
import HomepageStyles from '../assets/JSS/HomepageStyles';

class Home extends Component {
  state = {
    loading: true,
    loadingPemenang: false,
    loadingPeserta: false,
    listPemenang: {},
    listPeserta: {},
    listVideo: [],
    currentVideo: "",
    title: "",
    deskripsi: "",
    media_type: "",
    media_url: "",
    file_logo: "",
  };

  async componentDidMount() {
    let listPemenang = await this.getPemenang();
    // let listPeserta = await this.getPeserta();
    let listVideo = await this.getContentVideo();
    let deskripsi = await this.getDeskripsiHome();

    if (deskripsi && deskripsi.length > 0) {
      deskripsi = {
        title: deskripsi[0].title,
        deskripsi: deskripsi[0].deskripsi,
        media_type: deskripsi[0].media_type,
        media_url: deskripsi[0].media_url,
        file_logo: deskripsi[0].file_logo,
      }
    }
    else
      deskripsi = {};

    this.setState({
      listPemenang,
      // listPeserta,
      listVideo,
      ...deskripsi,
      currentVideo: listVideo.length > 0 ? listVideo[0].youtube_video_id : "",
      loading: false
    });
  }

  async getPemenang(pageNum = 0) {
    // this.setState({ loading: true });
    
    let data = null;
    let url = api_list.listPemenang.url;
    if(pageNum > 0) {
      url += `?page=${pageNum}`
    }
    try {
      data = await API({
        api: url,
        params: {
          method: api_list.listPemenang.method,
          headers: {
            'Content-Type': 'application/json',
            // "Authorization": localStorage.getItem("token")
          },
        },
      });
    } catch (error) {
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }

    console.log("data", data);
    return data;
    // this.setState({ loading: false });
  }

  async changePagePemenang(pageNum) {
    this.setState({ loadingPemenang: true });
    let listPemenang = await this.getPemenang(pageNum);
    this.setState({ listPemenang, loadingPemenang: false });
  }

  async getPeserta(pageNum = 0) {
    // this.setState({ loading: true });
    
    let data = null;
    let url = api_list.listPesertaGuest.url;
    if(pageNum > 0) {
      url += `?page=${pageNum}`
    }
    try {
      data = await API({
        api: url,
        params: {
          method: api_list.listPesertaGuest.method,
          headers: {
            'Content-Type': 'application/json',
            // "Authorization": localStorage.getItem("token")
          },
        },
      });
    } catch (error) {
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }

    console.log("data", data);
    return data;
    // this.setState({ loading: false });
  }

  async changePagePeserta(pageNum) {
    this.setState({ loadingPeserta: true });
    let listPeserta = await this.getPeserta(pageNum);
    this.setState({ listPeserta, loadingPeserta: false });
  }

  async getContentVideo() {
    let data = null;
    try {
      data = await API({
        api: api_list.contentVideo.url,
        params: {
          method: api_list.contentVideo.method,
          headers: {
            'Content-Type': 'application/json',
            // "Authorization": localStorage.getItem("token")
          },
        },
      });
    } catch (error) {
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }

    console.log("data getContentVideo", data);
    return data.map(v => ({ ...v, youtube_video_id: v.youtube_url }));
    // this.setState({ loading: false });
  }
  
  async getDeskripsiHome() {
    let data = null;
    try {
      data = await API({
        api: api_list.deskripsiHome.url,
        params: {
          method: api_list.deskripsiHome.method,
          headers: {
            'Content-Type': 'application/json',
            // "Authorization": localStorage.getItem("token")
          },
        },
      });
    } catch (error) {
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }

    console.log("data getDeskripsiHome", data);
    return data;
    // this.setState({ loading: false });
  }

  render() {
    let { 
      listPemenang, 
      listPeserta, 
      loading, 
      loadingPemenang,
      loadingPeserta,
      currentVideo, 
      listVideo,
      title,
      deskripsi,
      media_type,
      media_url,
      file_logo,
    } = this.state;
    let { classes } = this.props;

    let bg_media = (
      <video style={{ height: "100%" }} autoPlay={true} loop={true} muted={true}>
        <source src={require("../assets/video/vid1.mp4")} />
      </video>
    );
    try {
      if (media_url !== "") {
        bg_media = (
          <video style={{ height: "100%" }} autoPlay={true} loop={true} muted={true}>
            <source src={`${base_url}../media/${media_url}`} />
          </video>
        );
        if (media_type === "image") {
          bg_media = <img alt={media_url} src={`${base_url}../media/${media_url}`} style={{ height: "100%" }} />
        }
      }
    } catch (error) {
      console.warn("Error while getting image", error);
    }
    
    return (
      <Box>

        <Box className={classes.topBox}>
          <Box className={classes.topBoxImgContTop}>
            <Box className={classes.topBoxImgCont}>
              {/* <img src="https://lonelymoon.net/wp-content/uploads/2017/04/sky.jpg" style={{ width: "100%" }} /> */}
              {/* <video style={{ height: "100%" }} autoPlay={true} loop={true} muted={true}>
                <source src={require("../assets/video/vid1.mp4")} type="video/*" />
              </video> */}
              {bg_media}
            </Box>
          </Box>
          <Box className={classes.topBoxTextCont}>
            <Container maxWidth="lg" className={classes.topBoxText}>
              <h1>{title}</h1>
              {/* <h5>
                {deskripsi}
              </h5> */}
              {
                file_logo &&
                <img alt="Logo" src={`${base_url}../media/${file_logo}`} style={{ height: "20%" }} className={classes.logo} />
                // <img alt="Logo" src={require("../assets/f436e2db6bca853f53b6c75a47df835b.jpeg")} className={classes.logo} />
              }
            </Container>
          </Box>
        </Box>
        <Container maxWidth="xl" style={{ backgroundColor: "#EFEFEF", position: "relative", zIndex: 1 }}>
          <Container maxWidth="lg" className={classes.root}>

            <Box className={classes.topCont}>
              {
                currentVideo !== "" &&
                <iframe title="youtube video" className={classes.mainVideo} src={`https://www.youtube.com/embed/${currentVideo}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
              }
            </Box>

            <Box style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
              <Grid container sm={10} md={8} spacing={3} style={{ padding: "60px 10px 40px", justifyContent: "center" }}>
                {
                  listVideo.map(v => (
                    <Grid key={v.id} item md={3} sm={6} xs={6} onClick={() => this.setState({ currentVideo: v.youtube_video_id })} style={{ height: 150 }}>
                      <Paper className={classes.paper} elevation={5} style={{ background: `url("http://img.youtube.com/vi/${v.youtube_video_id}/0.jpg")`, backgroundSize: "cover", backgroundPosition: "center", height: "100%" }}>
                        {/* <img src={`http://img.youtube.com/vi/${v.youtube_video_id}/0.jpg`} style={{ width: "100%", height: "100%" }} /> */}
                      </Paper>
                    </Grid>
                  ))
                }
              </Grid>
            </Box>

            <Grid container lg={10} md={10} style={{ marginBottom: 50, opacity: listPemenang.total > 0 ? 1 : 0 }}>
              <h3 style={{ marginLeft: "auto", marginRight: "auto" }}>Daftar pemenang</h3>
              <Table datas={listPemenang} changePage={pageNum => this.changePagePemenang(pageNum)} loading={loadingPemenang} />
            </Grid>
            
            {/* <Grid container lg={10} md={10} style={{ marginBottom: 50, opacity: listPeserta.total > 0 ? 1 : 0 }}>
              <h3 style={{ marginLeft: "auto", marginRight: "auto" }}>Daftar peserta</h3>
              <TablePeserta datas={listPeserta} changePage={pageNum => this.changePagePeserta(pageNum)} loading={loadingPeserta} />
            </Grid> */}
            
            <Grid container xs={12} style={{ borderTop: "1px solid black", padding: "40px 0 10px", justifyContent: "center" }}>
              <Grid item md={3}>
                <Button
                  variant="contained" 
                  color="primary" 
                  size="medium" 
                  className={classes.btnMulai} 
                  style={{ fontSize: 20 }}
                  onClick={() => this.props.history.push("/register")}
                  disabled={loading}
                >
                  Daftar Sekarang
                </Button>
              </Grid>
            </Grid>

          </Container>
        </Container>
        <Container maxWidth="xl" style={{ backgroundColor: "rgb(49,49,49)", position: "relative", zIndex: 1, alignItems: "center", justifyContent: "center", paddingTop: 20, paddingBottom: 20 }}>
          <Container maxWidth="sm">
            <h5 style={{ color: "#FFF", textAlign: "center" }}>
              {deskripsi}
            </h5>
          </Container>
        </Container>

        <LoadingSpinner loading={loading} />

      </Box>
    )
  }
}

export default withRouter(withTheme(withStyles(HomepageStyles)(Home)));