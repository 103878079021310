import API, { api_list } from "./API";

export const randomLetter = () => {
  return "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
};

export const getRandomLetter = () => {
  const randomL = randomLetter();
  let min = 0;
  let max = randomL.length-1;
  return randomL[ Math.floor(Math.random() * (max - min + 1)) + min ];
};

export var interv = null;
export const randomizeLetter = (sumLetter, cb, done) => {
  let result = [];
  interv = setInterval(() => {
    for (let i = 0; i < sumLetter; i++) 
      result[i] = getRandomLetter();
    cb(result);
  }, 100);
  
  // setTimeout(() => {
  //   clearTimeout(interv);
  //   done(result);
  // }, 3000);
};

export const checkAuth = async () => {
  let data = false;  
  try {
    data = await API({
      api: api_list.check_token.url,
      params: {
        method: api_list.check_token.method,
        headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("token")
        },
      },
    });
    // data = !data && data.token != "";
  } catch (error) {
    alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
  }

  return data;
}