import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Icon from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import { Button, Box, TableFooter, Select, MenuItem } from '@material-ui/core';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  paper: {
    "&.MuiPaper-root:hover": {
      cursor: "pointer"
    }
  }
});

export default function CustomizedTables(props) {
  const classes = useStyles();
  const { saveRow, deleteRow, addRow, datasNotMenang } = props;
  const [tambah, setTambah] = useState(false);
  const [newDatas, setNewDatas] = useState({
    id_peserta: ""
  });

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ maxWidth: 30 }}>No</StyledTableCell>
            <StyledTableCell>Nama</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Tgl Lahir</StyledTableCell>
            <StyledTableCell>Kode Undian</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Telepon</StyledTableCell>
            <StyledTableCell>Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, i) => (
            <Rows
              row={row}
              classes={classes}
              key={i}
              num={i + 1}
              onSave={dt => saveRow(dt)}
              onDelete={id => deleteRow(id)}
            />
          ))}
        </TableBody>
        {
          tambah ?
            <TableFooter>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell colSpan={5}>
                  <Select
                    value={newDatas.id_peserta}
                    onChange={event => setNewDatas({ id_peserta: event.target.value })}
                    style={{ minWidth: 150 }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {
                      datasNotMenang.map((v, k) => (
                        <MenuItem key={k} value={v.p_id}>{`${v.nama} | ${v.kode_undian} | ${v.email}`}</MenuItem>
                      ))
                    }
                  </Select>
                </StyledTableCell>
              </StyledTableRow>
            </TableFooter>
          :
            null
        }
      </Table>
      <Box style={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
        {
          tambah ? 
            <Box>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: 10 }}
                onClick={() => {
                  setTambah(false);
                  addRow(newDatas);
                }}
              >
                Simpan
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setTambah(false);
                  setNewDatas({ nama: "", email: "", tgl_lahir: "", kode_undian: "" });
                }}
              >
                Batal
              </Button>
            </Box>
          :
            <Button
              variant="contained"
              color="primary"
              onClick={() => setTambah(true)}
            >
              + Tambah Data
            </Button>
        }
      </Box>
    </TableContainer>
  );
}

const Rows = props => {
  const { row, classes, num, onDelete } = props;
    
  return (
    <StyledTableRow>
      <StyledTableCell style={{ maxWidth: 30 }}>
        {num}
      </StyledTableCell> 
      <StyledTableCell>
        {
          row.nama
        }
      </StyledTableCell>
      <StyledTableCell>
        {
          row.email
        }
      </StyledTableCell>
      <StyledTableCell>
        {
          row.tgl_lahir
        }
      </StyledTableCell>
      <StyledTableCell>
        {
          row.kode_undian
        }
      </StyledTableCell>
      <StyledTableCell>
        {
          row.email
        }
      </StyledTableCell>
      <StyledTableCell>
        {
          row.telp
        }
      </StyledTableCell>
      <StyledTableCell style={{ maxWidth: 30 }}>
        <Grid container spacing={3}>
          
          <Grid item>
            <Paper className={classes.paper} style={{ paddingLeft: 6, paddingRight: 6 }}>
              <Icon path={mdiDelete}
                title="Delete"
                size={1}
                color="red"
                onClick={() => {
                  if (window.confirm("Apakah Anda yakin?")) {
                    onDelete(row.id);
                  }
                }}
              />
            </Paper>
          </Grid>

        </Grid>
      </StyledTableCell>
    </StyledTableRow>
  );
}