import React, { Component } from 'react'
import { Container, Box, Grid, CircularProgress, Button, TextField } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import API, { api_list } from '../API';
import ReCAPTCHA from 'react-google-recaptcha';

const styles = {
  root: {
    paddingTop: 10,
    marginTop: 40,
    marginBottom: 40,
    minHeight: 650,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    boxShadow: "0px 0px 50px -15px rgba(0,0,0,0.75)"
  },
  textField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFF",
      borderRadius: 15,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 2,
      borderColor: "rgba(32,32,82,.5)",
      "& legend": {
        fontSize: 15
      }
    },
    // "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    //   transform: "translate(14px, -6px) scale(1)"
    // },
    "& .MuiFormHelperText-root": {
      textAlign: "right"
    }
  },
  btn: {
    width: "100%",
    fontSize: 20,
    fontFamily: ["Segoe UI", "Arial"].join(",")
  },
  fullScreenLoading: { 
    position: "fixed", 
    height: "100vh", 
    width: "100vw", 
    top: 0,
    left: 0,
    backgroundColor: "rgba(255,255,255,.5)", 
    display: "flex", 
    flexDirection: "column",
    alignItems: "center", 
    justifyContent: "center",
    transition: "all 1s",
    "& p": { 
      fontSize: 20,
      textShadow: "0px 0px 10px rgba(0,0,0,0.59)"
    }
  },
};

class Registration extends Component {
  state = {
    loading: false,
    nama: { value: "", error: false },
    email: { value: "", error: false },
    tglLahir: { value: "", error: false },
    telp: { value: "", error: false },
    captcha: { value: "", error: false },
  }

  validateInput() {
    let { nama, email, telp, captcha } = this.state;
    let is_good = true;
    if(nama.value === "") {
      this.setState({ nama: {...nama, error: true} });
      is_good = false;
    }
    if(email.value === "") {
      this.setState({ email: {...email, error: true} });
      is_good = false;
    }
    if(telp.value === "") {
      this.setState({ telp: {...telp, error: true} });
      is_good = false;
    }
    if(captcha.value === "") {
      this.setState({ captcha: {...captcha, error: true} });
      is_good = false;
    }
    return is_good;
  }

  async onSubmit() {
    if(!this.validateInput()) return;
    this.setState({ loading: true });
    
    let { nama, email, telp, captcha } = this.state;
    let data = null;
    try {
      data = await API({
        api: api_list.register.url,
        params: { method: api_list.register.method },
        data: {
          nama: nama.value,
          email: email.value,
          // tgl_lahir: tglLahir.value,
          telp: telp.value,
          captcha: captcha.value,
        }
      });
    } catch (error) {
      alert(`Telah terjadi kesalahan:\n${error}`);
    }

    console.log("data", data);
    this.setState({ loading: false });
    if (data.error) {
      alert(data.msg);
    }
    if (data !== null && data.status) {
      this.props.history.push(`/registration-success/${data.kode_undian}`);
    }
  }

  render() {
    let { classes } = this.props;
    let { nama, email, telp, captcha } = this.state;
    let loadingStyle = this.state.loading ? { opacity: 1, zIndex: 10000 } : { opacity: 0, zIndex: -1 };
    
    return (
      <Container maxWidth="sm" className={classes.root}>

        <Grid container spacing={3}>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <img src={require("../assets/147140.png")} alt="profile ico" />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", fontFamily: ["Segoe UI", "Arial"].join(","), fontSize: 35 }}>
            Pendaftaran Undian:
          </Grid>

        </Grid>
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <TextField
              required
              className={classes.textField}
              id="nama-required"
              label="Nama"
              defaultValue=""
              variant="outlined"
              helperText={nama.error ? "Harus diisi" : ""}
              error={nama.error}
              onChange={ev => this.setState({ nama: {value: ev.target.value, error: false} })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              className={classes.textField}
              id="email-required"
              label="Email"
              defaultValue=""
              variant="outlined"
              helperText={email.error ? "Harus diisi" : ""}
              error={email.error}
              onChange={ev => this.setState({ email: {value: ev.target.value, error: false} })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              className={classes.textField}
              id="tgl-required"
              label="Nomor Telepon"
              defaultValue=""
              variant="outlined"
              helperText={telp.error ? "Harus diisi" : ""}
              error={telp.error}
              onChange={ev => this.setState({ telp: {value: ev.target.value, error: false} })}
            />
          </Grid>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", border: captcha.error ? "1px solid red" : 0 }}>
            {/* <div id="recaptcha" className="g-recaptcha" data-sitekey="6LcO7-cUAAAAALJomVzjJkVhhPKEq2SRa_wUQ6BJ"></div> */}
            <ReCAPTCHA
              sitekey="6LcO7-cUAAAAALJomVzjJkVhhPKEq2SRa_wUQ6BJ"
              onChange={captcha => this.setState({ captcha: { value: captcha, error: false } })}
            />
          </Grid>

        </Grid>
        <Grid container>
          <Grid xs={12} item>
            <Button variant="contained" color="primary" size="large" className={classes.btn} onClick={this.onSubmit.bind(this)}>
              DAFTAR
            </Button>
          </Grid>
        </Grid>
        <Box className={classes.fullScreenLoading} style={loadingStyle}>
          <CircularProgress />
          <p>Loading...</p>
        </Box>

      </Container>
    )
  }
}

const applyTheme = withTheme(withStyles(styles)(Registration));
export default withRouter(applyTheme);