import React, { Component } from 'react'
import { Container, Box, Grid, CircularProgress, Button, TextField } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import API, { api_list } from '../API';

const styles = {
  root: {
    marginTop: 40,
    marginBottom: 40,
    minHeight: 500,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    boxShadow: "0px 0px 50px -15px rgba(0,0,0,0.75)"
  },
  textField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFF",
      borderRadius: 15,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 2,
      borderColor: "rgba(32,32,82,.5)",
      "& legend": {
        fontSize: 15
      }
    },
    // "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    //   transform: "translate(14px, -6px) scale(1)"
    // },
    "& .MuiFormHelperText-root": {
      textAlign: "right"
    }
  },
  btn: {
    width: "100%",
    fontSize: 20,
    fontFamily: ["Segoe UI", "Arial"].join(",")
  },
  fullScreenLoading: { 
    position: "fixed", 
    height: "100vh", 
    width: "100vw", 
    top: 0,
    left: 0,
    backgroundColor: "rgba(255,255,255,.5)", 
    display: "flex", 
    flexDirection: "column",
    alignItems: "center", 
    justifyContent: "center",
    transition: "all 1s",
    "& p": { 
      fontSize: 20,
      textShadow: "0px 0px 10px rgba(0,0,0,0.59)"
    }
  },
};

class Login extends Component {
  state = {
    loading: false,
    nama: { value: "", error: false },
    password: { value: "", error: false },
    errMsg: "",
  }

  validateInput() {
    let { nama, password } = this.state;
    let is_good = true;
    if(nama.value === "") {
      this.setState({ nama: {...nama, error: true} });
      is_good = false;
    }
    if(password.value === "") {
      this.setState({ password: {...password, error: true} });
      is_good = false;
    }
    return is_good;
  }

  async onSubmit() {
    if(!this.validateInput()) return;
    this.setState({ loading: true });
    
    let { nama, password } = this.state;
    let data = null;
    try {
      data = await API({
        api: api_list.loginAdmin.url,
        params: { method: api_list.loginAdmin.method },
        data: {
          username: nama.value,
          password: password.value,
        }
      });
    } catch (error) {
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }

    console.log("data", data);
    if (data && data.code === 200 && data.result) {
      localStorage.setItem("token", data.result.token);
      this.props.onRefresh();
      this.setState({ loading: false });
      this.props.history.push("/admin-home");
    }
    else {
      this.setState({ loading: false, errMsg: data.message });
    }
  }

  render() {
    let { classes } = this.props;
    let { nama, password, errMsg } = this.state;
    let loadingStyle = this.state.loading ? { opacity: 1, zIndex: 10000 } : { opacity: 0, zIndex: -1 };
    
    return (
      <Container maxWidth="sm" className={classes.root}>

        <Grid container spacing={3}>

          {/* <Grid item xs={12} style={{ textAlign: "center" }}>
            <img src={require("../assets/147140.png")} alt="profile ico" />
          </Grid> */}
          <Grid item xs={12} style={{ textAlign: "center", fontFamily: ["Segoe UI", "Arial"].join(","), fontSize: 35 }}>
            Login
          </Grid>

        </Grid>
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <TextField
              required
              className={classes.textField}
              id="nama-required"
              label="Nama"
              defaultValue=""
              variant="outlined"
              helperText={nama.error ? "Harus diisi" : ""}
              error={nama.error}
              onChange={ev => this.setState({ nama: {value: ev.target.value, error: false} })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              type="password"
              className={classes.textField}
              id="password-required"
              label="Password"
              defaultValue=""
              variant="outlined"
              helperText={password.error ? "Harus diisi" : ""}
              error={password.error}
              onChange={ev => this.setState({ password: {value: ev.target.value, error: false} })}
            />
          </Grid>
          <Grid item xs={12} style={{ border: "1px solid red", borderRadius: 5, margin: "10px 15px 0", display: errMsg === "" ? "none" : "block" }}>
            <p style={{ textAlign: "center", margin: 0 }}>{errMsg}</p>
          </Grid>

        </Grid>
        <Grid container>
          <Grid xs={12} item>
            <Button variant="contained" color="primary" size="large" className={classes.btn} onClick={this.onSubmit.bind(this)}>
              Login
            </Button>
          </Grid>
        </Grid>
        <Box className={classes.fullScreenLoading} style={loadingStyle}>
          <CircularProgress />
          <p>Loading...</p>
        </Box>

      </Container>
    )
  }
}

const applyTheme = withTheme(withStyles(styles)(Login));
export default withRouter(applyTheme);