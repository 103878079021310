import React, { Component } from 'react';
import { Container, Grid, Paper, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import '../App.css';
import { randomizeLetter, interv } from '../Library';
import API, { api_list } from '../API';
import Table from './Table';
import '../star_animation.css';

const styles = {
  root: {
    paddingTop: 20,
    minHeight: 600,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0
  },
  topCont: {
    boxShadow: "0px 0px 50px -15px rgba(0,0,0,0.75)",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  boxLetterCont: {
    padding: "40px 10px 140px",
    justifyContent: "center"
  },
  boxLetter: {
    flex: 1,
    maxWidth: 120,
    maxHeight: 120
  },
  paper: ({theme}) => {
    return {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.warning.light,
      fontSize: "3rem",
      fontFamily: ['Space Mono', 'Consolas', 'Arial'].join(",")
    }
  },
  btnMulai: {
    marginBottom: 50,
    width: "100%",
    fontSize: 30,
    "&.Mui-disabled": {
      backgroundColor: "#aaa",
      color: "#FFF"
    }
  }
};

class Home extends Component {
  state = {
    randomLetter: ["-", "-", "-", "-", "-"],
    detailPemenang: {},
    alertPemenang: false,
    loading: true,
    loadingPemenang: false,
    listPemenang: {},
    animStart: false,
    stars: [],
  };
  audio = new Audio(require('../assets/winner_sound.mp3'));

  async componentDidMount() {
    let listPemenang = await this.getPemenang();
    this.setState({ listPemenang, loading: false });
  }

  showStars() {
    let stars = [];
    // let rand = this.randomInt(60, 70);
    let rand = 100;
    for (let i = 0; i < rand; i++) {
      stars.push(false);
    }
    setTimeout(() => this.audio.play(), 1000);
    setTimeout(() => this.setState({ stars: [] }), 12000);
    this.setState({ stars });
  }

  randomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    let rand = Math.floor(Math.random() * (max - min + 1)) + min;
    return rand;
  }

  async randomizeLetter() {
    // this.setState({ alertPemenang: true, detailPemenang: {nama: 'Lorem ipsum'} });
    // this.showStars();
    // return;
    let { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });

    randomizeLetter(5, r => this.setState({ randomLetter: r }), r => null);
    let pemenang = await this.getPesertaRandom();
    
    if (!pemenang || pemenang.length <= 0) {
      // pemenang.push({ kode_undian: "-----" });
      setTimeout(() => {
        clearTimeout(interv)
        this.setState({ randomLetter: "-----".split(""), loading: false });
      }, 200);
      return;
    }

    pemenang = pemenang[0];
    let kodePemenang = pemenang.kode_undian.split("");
    
    setTimeout(async () => {
      // listPemenang.splice(0, 0, pemenang);
      clearTimeout(interv)
      let listPemenang = await this.getPemenang();
      this.setState({ listPemenang: listPemenang, randomLetter: kodePemenang, loading: false, alertPemenang: true, detailPemenang: pemenang });
      this.showStars();
    }, 3000);
  }

  async getPesertaRandom() {
    // this.setState({ loading: true });
    
    let data = null;
    try {
      data = await API({
        api: api_list.undian.url,
        params: {
          method: api_list.undian.method,
          headers: {
            'Content-Type': 'application/json',
            "Authorization": localStorage.getItem("token")
          },
        },
      });
    } catch (error) {
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }

    console.log("data", data);
    return data;
    // this.setState({ loading: false });
  }

  async getPemenang(pageNum = 0) {
    // this.setState({ loading: true });
    
    let data = null;
    let url = api_list.listPemenang.url;
    if(pageNum > 0) {
      url += `?page=${pageNum}`
    }
    try {
      data = await API({
        api: url,
        params: {
          method: api_list.listPemenang.method,
          headers: {
            'Content-Type': 'application/json',
            // "Authorization": localStorage.getItem("token")
          },
        },
      });
    } catch (error) {
      alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
    }

    console.log("data", data);
    return data;
    // this.setState({ loading: false });
  }

  async changePagePemenang(pageNum) {
    this.setState({ loadingPemenang: true });
    let listPemenang = await this.getPemenang(pageNum);
    this.setState({ listPemenang, loadingPemenang: false });
  }

  onLogout() {
    localStorage.clear();
    window.location.reload();
  }

  togleDialogPemenang(show) {
    this.setState({ alertPemenang: show });
  }

  alertPemenang(nama_pemenang) {
    return (
      <Dialog
        open={this.state.alertPemenang}
        onClose={() => this.togleDialogPemenang(false)}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Pemenang</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h1 style={{ textAlign: 'center' }}>
              {nama_pemenang}
            </h1>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.togleDialogPemenang(false)} color="primary" autoFocus>
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    let { randomLetter, listPemenang, loading, loadingPemenang, detailPemenang, stars } = this.state;
    let { classes } = this.props;
    
    return (
      <Container maxWidth="lg" className={classes.root}>

        <div class='star-container'>
          {
            stars.map(v => (
              <img
                ref={r => {
                  setTimeout(() => {
                    if (r) {
                      r.className = 'slide-bottom';
                    }
                  }, this.randomInt(0, 6500));
                }}
                src={require('../assets/gold_star.png')}
                style={{ width: 70, height: 70, position: 'fixed', top: -80, left: `${this.randomInt(0, 95)}vw` }}
              />
            ))
          }
        </div>
        <div className={classes.topCont}>

          {this.alertPemenang(detailPemenang.nama || '')}
          <Grid container sm={10} md={8} spacing={1} className={classes.boxLetterCont}>

            <Grid item className={classes.boxLetter}>
              <Paper className={classes.paper} elevation={5}>
                { randomLetter[0] }
              </Paper>
            </Grid>
            <Grid item className={classes.boxLetter}>
              <Paper className={classes.paper} elevation={5}>
                { randomLetter[1] }
              </Paper>
            </Grid>
            <Grid item className={classes.boxLetter}>
              <Paper className={classes.paper} elevation={5}>
                { randomLetter[2] }
              </Paper>
            </Grid>
            <Grid item className={classes.boxLetter}>
              <Paper className={classes.paper} elevation={5}>
                { randomLetter[3] }
              </Paper>
            </Grid>
            <Grid item className={classes.boxLetter}>
              <Paper className={classes.paper} elevation={5}>
                { randomLetter[4] }
              </Paper>
            </Grid>

          </Grid>
          <Grid container justify="center">
            <Grid md={6} xs={11}>
              <Button
                variant="contained" 
                color="primary" 
                size="large" 
                className={classes.btnMulai} 
                onClick={this.randomizeLetter.bind(this)}
                disabled={loading}
              >
                {loading ? "LOADING" : "MULAI"}
              </Button>
            </Grid>
          </Grid>
        </div>
        <Grid container lg={10} md={10} style={{ marginBottom: 50, opacity: listPemenang.total > 0 ? 1 : 0 }}>
          <h1 style={{ marginLeft: "auto", marginRight: "auto" }}>Tabel daftar pemenang</h1>
          {/* <Table rows={listPemenang} /> */}
          <Table datas={listPemenang} changePage={pageNum => this.changePagePemenang(pageNum)} loading={loadingPemenang} />
        </Grid>
        {/* <Grid container lg={10} md={10}>
          <Grid item>
            <Button color="primary" onClick={() => this.onLogout()}>Logout</Button>
          </Grid>
        </Grid> */}

      </Container>
    )
  }
}

export default withTheme(withStyles(styles)(Home));