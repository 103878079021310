import React, { Component } from 'react';
import { Box, Grid, withStyles } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';

const styles = {
  menuCont: {
    position: "fixed", 
    left: 0, 
    top: 0, 
    bottom: 0, 
    maxWidth: "250px", 
    transition: "1s all",
    backgroundColor: "#0000a4",
    boxShadow: "3px 0px 15px 0px rgba(0,0,0,0.75)",
    zIndex: 100
  },
  linkCont: {
    color: "#FFF",
    transition: ".9s all",
    "&:hover": {
      transition: ".9s all",
      backgroundColor: "#00000088"
    }
  },
  link: {
    display: "block",
    color: "#E0E0E0",
    textDecoration: "none",
    transition: ".5s all",
    width: "100%",
    padding: "15px 15px 15px 30px",
    "&:hover": {
      color: "#FF0",
      transition: ".5s all",
      marginLeft: 10
    }
  },
};

class AdminMenu extends Component {
  state = {
    hide: true
  };

  ItemList(props) {
    let { classes } = props;
    return (
      <Grid item xs={12} className={classes.linkCont} onClick={() => typeof props.action == "function" && props.action()}>
        <Link to={props.link} className={classes.link}>{props.nama}</Link>
      </Grid>
    );
  }

  render() {
    let { classes } = this.props;
    let { hide } = this.state;
    const datas = [
      {id: 1, link: "/admin-undian", nama: "Undian"},
      {id: 2, link: "/admin-home", nama: "Home"},
      {id: 3, link: "/admin-peserta", nama: "Peserta"},
      {id: 4, link: "/admin-pemenang", nama: "Pemenang"},
      {id: 5, link: "/admin-users", nama: "Users"},
      {id: 6, link: "/", nama: "Logout", action: () => localStorage.clear()},
    ]
    return (
      <Box>
        <Box className={classes.menuCont} onClick={() => this.setState({ hide: false })}>
          <h2 style={{ color: "#FFF", marginRight: 5, cursor: "pointer", paddingLeft: 10, paddingRight: 10 }}>
            {`>`}
          </h2>
        </Box>
        <Box className={classes.menuCont} style={{ left: hide ? -1000 : 0 }}>
          <Grid container style={{ borderBottomWidth: 1, borderBottomColor: "#FFF" }}>
            <Grid item xs={10}>
              <h2 style={{ color: "#FFF", textAlign: "center" }}>
                Menu
              </h2>
            </Grid>
            <Grid item xs={2} onClick={() => this.setState({ hide: true })}>
              <h2 style={{ color: "#FFF", marginRight: 5, cursor: "pointer" }}>
                {`<`}
              </h2>
            </Grid>
          </Grid>
          <Grid container>
            {
              datas.map(v => (
                <this.ItemList key={v.id} {...v} classes={classes} />
              ))
            }
          </Grid>
        </Box>
      </Box>
    )
  }
}

export default withRouter(withStyles(styles)(AdminMenu));