// export const base_url = "http://localhost:8000/"; // dev local
export const base_url = `${window.location.origin}/server/`; // Dynamic production URL
export const api_list = {
  undian: {
    url: "mulai-undian",
    method: "GET"
  },
  register: {
    url: "peserta",
    method: "POST"
  },
  listPeserta: {
    url: "peserta",
    method: "GET"
  },
  listPesertaGuest: {
    url: "peserta-guest",
    method: "GET"
  },
  listPemenang: {
    url: "pemenang",
    method: "GET"
  },
  contentVideo: {
    url: "content-video",
    method: "GET"
  },
  deskripsiHome: {
    url: "deskripsi-home",
    method: "GET"
  },
  loginAdmin: {
    url: "login",
    method: "POST"
  },
  check_token: {
    url: "check_token",
    method: "POST"
  },
};
  
export default async ({ api, params, data, formData = false }) => {
  let result = null;
  let default_params = {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    // mode: 'no-cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *client
  }
  if (params.method !== "GET") default_params.body = JSON.stringify(data || {});
  if (formData !== false) default_params.body = formData;
  try {
    // console.log(">>>", `${base_url}${api}`, {...default_params, ...params});
    let response = await fetch(`${base_url}${api}`, {...default_params, ...params});
    result = await response.json();
  } catch (error) {
    console.warn(error);
    throw new Error(error);
    // alert(`Telah terjadi kesalahan ketika menghubungi server:\n${error}`);
  }

  return result;
}